@import '~antd/dist/antd.css';
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Override base SVG style to work with Ant Design if using external icon sets */
svg {
  vertical-align: initial;
}

.full-card {
  border: 2px solid #bfc5d9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.full-card .ant-card-body{
  padding: 0
}

.stat-card {
  border: 2px solid #bfc5d9;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.jcollapse {
  border: 2px solid #bfc5d9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;;
}

.jcollapse {
  border-radius: 8px;
}

.jcollapse .ant-collapse-header-text{
  font-weight: 500;
}

.jpanel-custom .ant-collapse-content-box {
  padding: 0;
}

.jtable{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border: 2px solid #d9bfbf;
  border-radius: 10;
  max-width: 1536px;
  width: 100%;
}

.clickable-row{
  cursor: pointer;
}

.clickable-cursor{
  cursor: pointer;
}

.default-cursor{
  cursor: default;
}

.policies-table{
  border-collapse: collapse;
  padding: -10px;
}
.policies-table tr td {
  border-bottom: 2px solid #d9d9d9;
}

.jtable-policy-row {
  background-color: #f5f5f5;
  cursor: pointer;
}


.nested-table-row{
  background-color: #f5f5f5;
}

.nested-table-row td:first-child{
  border-left: 1px solid #d9d9d9;
  cursor: default;
}

.jtable-policy-row td:first-child{
  border-left: 1px solid #d9d9d9;
}

.jtable-policy-row {
  background-color: #f5f5f5;
  cursor: pointer;
}

.check-table {
  font-size: 14px;
}

.check-table td{
  border-left: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  cursor: default;
}

.check-table td:last-child{
  border-right: 1px solid #d9d9d9;
}

.control-table {
  border-collapse: collapse;
}

.ant-table.ant-table-small .ant-table-title {
  padding: 0;
  border: 1px solid #d9d9d9;
}

.ant-table.ant-table-small .ant-table-footer {
  padding: 0;
  border: 1px solid #d9d9d9;
}

.check-table-more {
  width: 100%;
  cursor: pointer;
  display:flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.control-table-row td:first-child{
  border-left: 1px solid #d9d9d9;
}

.control-table tr td {
  border-bottom: 2px solid #d9d9d9;
}

.side-menu {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;

  background: #111827;
  border-right: 1px solid #bfc5d9;
}

.ant-menu-dark .ant-menu-item-divider {
  margin-top: 24px;
  margin-bottom: 24px;
  border-color: #2D3748;
}

.ant-menu-dark .ant-menu-title-content {
  font-size: 0.875rem;
  font-weight: 600;
  color: #D1D5DB;
}

.ant-menu-dark .ant-menu-item-icon {
  font-size: 20px;
}

.white-font {
  color: #d9d9d9;
  font-weight: 700;
}

.gray-font {
  color: #bfbfbf;
  font-weight: 500;
}

.check-table .ant-table-thead tr th{
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-collapse: collapse;
}

.check-table .ant-table-cell th{
}

.check-table .ant-table-tbody tr td{
  padding: 100px;
}

.last-result-modal .ant-modal-body {
  padding: 0;
}

/*---------------------------------------*/
/*
.table-tag {
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.small-table-text {
  font-size: 13px;
}

/*------------------Drawer---------------------*/
/*
.drawer-category-header {
  font-size: 20px;
  font-weight:60;
  color: rgb(68, 80, 95);
}

.drawer-field {
  display: grid;
  grid-template-columns: 170px 1fr;
  gap: 48px;
  padding-left: 4px;
}

.drawer-field .ant-form-item{
  margin-bottom: 0px;
}

.drawer-field-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 4px;
}

/*------------------Affectation---------------------*/
/*
.affectation-box {
  border: 1px solid rgb(228, 231, 236);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: white;
  padding-top: 0px;
}
.affectation-box .ant-collapse-header:hover{
  background-color: #F8F8F8;
}

.affectation-title{
  font-size: 14px;
  font-weight: 500;
}

.affectation-assigned-title{
  font-weight: 600;
}

/*------------------Settings---------------------*/
/*
.settings-modal .ant-modal-body {
  padding-top: 0px;
}

.project-element {
  color: rgb(10, 28, 51);
  border-radius: 4px;
  border: 1px solid rgb(209, 216, 224);
  cursor: default;

  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.project-element .content {
  display: flex;
  flex-direction: column;
  gap: 3px
}

.project-element .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  text-align: left;
}

.project-element .subtitle {
  color: rgb(68, 80, 95);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

/*------------------Général---------------------*/
/*
.page-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
}

.ant-modal-header{
  border-radius: 8px;
}

.ant-modal-content{
  border-radius: 8px;
}

.ant-btn{
  border-radius: 4px;
}

.body-inner{
  background: #f6f6f6

}

/*------------------Login---------------------*/
/*
.auth-form-container{
  position: absolute;
  display: 'flex';  
  top: 100px;
  left: 50%;
  transform: translate(-50%,0) !important;

  border: 1px solid #dfdfdf;
  background: #fff;
  padding: 25px 30px 56px;
  margin: 0 auto;
  width: 520px;
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 8px rgb(0 0 0 / 3%);
  box-shadow: 0 3px 8px rgb(0 0 0 / 3%);

  cursor: default;
}

.auth-form-container .header {
  line-height: 34px;
  font-size: 30px;
  font-weight: 700;
  color: #222;
}

.auth-form-container .label {
  color: #222;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 1.5;
  white-space: initial;
  text-align: left;
}

.auth-form-container .ant-btn-submit{
  background: #6976d9;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  height: 40px;
}

.auth-form-container a{
  font-weight: 500;
  font-size: 14px;
}

.auth-form-container .password-lbl-ctn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.auth-form-container .ant-btn-submit span{
  font-size: 16px;
  font-weight: 600;
  color: white;
}*/